import ImageClient from '@robotsnacks/image-client';
import { debounce } from 'lodash';
import React, { Component, createRef } from 'react';
import {
  CardBlockBreakpointImageAttributes,
  CardBlockMediaOrientation,
} from './CardBlockAttributes';

import { Image, ImageUploader, ImageUploaderProps, cx } from '@robotsnacks/ui';

type Props = {
  client?: ImageClient;
  media: CardBlockBreakpointImageAttributes;
  resizing?: boolean;
  uploadUri?: string;
  uploadOptions?: any;
} & Pick<imageuploaderprops, 'onUploadComplete'="">;

export default class CardImage extends Component<props> {
  public static defaultProps = {
    blockSizes: [],
  };

  private _uploaderRef = createRef<typeof ImageUploader="">();

  public render() {
    const { client, media, uploadOptions, uploadUri, resizing } = this.props;
    return (
      <imageuploader {...this.props}="" height="{media.height}" width="{media.width}" style="{this._getStyle()}" uploadOptions="{uploadOptions}" uploadUri="{uploadUri}" onUploadComplete="{this.props.onUploadComplete}">
        {({ className, onTransitionEnd, onLoad }) => {
          if (!media.id) return null;

          if (resizing) {
            return (
              <div style="{{" position:="" 'absolute',="" height:="" '100%',="" width:="" background:="" '#eee',="" }}=""></div>
            );
          }

trở về (
            <image disablePinterest="" client="{client}" imageId="{media.id" as="" string}="" filename="{media.filename" height="{resizing" ?="" this._getDebouncedImageHeight()="" :="" media.height}="" width="{resizing" this._getDebouncedImageWidth()="" media.width}="" fit="cover" className="{cx(className)}" sources="{this._getPictureSources()}" onLoad="{onLoad}" onTransitionEnd="{onTransitionEnd}" style="{{" width:="" '100%',="" height:="" }}=""></image>
          );
        }}
      </imageuploader>
    );
  }

  private _getStyle() {
    const { height, width, orientation } = this.props.media;
    // TODO: We're manually setting the padding to `0` here to override the
    // default behavior of ImageUploader... which should probably be changed.
    if (orientation === CardBlockMediaOrientation.Vertical) {
      return {
        height: '100%',
        // height: `auto`,
        // minHeight: `${height}px`,
        width: '100%',
        paddingBottom: 0,
        position: 'absolute' as const,
        overflow: 'hidden',
      };
    } else {
      return {
        height: `100%`,
        width: `${width}px`,
        paddingBottom: 0,
        position: 'absolute' as const,
        overflow: 'hidden',
      };
    }
  }

  private _getDebouncedImageHeight = debounce(
    () => {
      return this.props.media.height;
    },
    300,
    { leading: true, trailing: true },
  );

  private _getDebouncedImageWidth = debounce(
    () => {
      return this.props.media.width;
    },
    300,
    { leading: true, trailing: true },
  );

  private _renderAverageColor() {
    // const { height, width } =
  }

  private _getPictureSources(): any[] {
    return [];
    // return this.props.blockSizes.map(size => ({
    //   ...props,
    //   ...this._calcImageSize(size),
    //   fit: 'crop' as 'crop',
    //   media: size.media,
    // }));
  }
}
</typeof></props></imageuploaderprops,>