import {
  Action,
  DeepPartial,
  Middleware,
  Reducer,
  Store,
  applyMiddleware,
  createStore as createReduxStore,
} from 'redux';
import { EpicMiddleware, createEpicMiddleware } from 'redux-observable';
import { EpicDependencies } from '../epics';
import { History } from 'history';
import { authMiddleware } from '../middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';

/**
 * Create store options.
 */
export interface CreateStoreOptions<s, A="" extends="" Action=""> {
  /**
   * Epic dependencies object for Redux Observable.
   */
  epicDependencies: EpicDependencies;

  /**
   * History object for Connected React Router.
   */
  history: History;

  middleware?: Middleware[];

  /**
   * The name of the store. This is used by Redux DevTools to distinguish
   * between multiple stores on the same page.
   */
  name: string;

  /**
   * Preloaded state, if any.
   */
  preloadedState?: DeepPartial<s>;

/**
   * Chức năng giảm tốc gốc redux.
   */
  rootReducer: Bộ giảm tốc<s, A="">;
}

/**
 * Tạo chức năng trợ giúp cửa hàng tạo thiết lập cửa hàng redux phía máy khách
 * bao gồm phần mềm trung gian cho Connected React Router, Redux Devtools và Redux
 *Quan sát.
 * Tùy chọn @param Tạo đối tượng tùy chọn cửa hàng.
 */
export const createStore = <s, A="" extends="" Action="">(
  tùy chọn: CreateStoreOptions<s, A="">,
): [Cửa hàng<s, A="">, EpicMiddleware<a>] => {
  const {
    epicDependencies,
    history,
    middleware = [],
    name,
    preloadedState = {},
    rootReducer,
  } = options;

  // Use Redux Devtools if present.
  const composeEnhancers = composeWithDevTools({
    name,
  });

  // Setup our redux-observable epic middleware.
  const epicMiddleware = createEpicMiddleware<a>({
    dependencies: epicDependencies,
  });

  const store = createReduxStore<s, A,="" {},="" {}="">(
    rootReducer,
    được tải sẵnState,
    composeEnhancers(
      applyMiddleware(
        epicMiddleware,
        routerMiddleware (lịch sử),
        authMiddleware(),
        ... phần mềm trung gian,
      ),
      applyMiddleware(synchronizeViewportUrlMiddleware()),
    ),
  );

trả lại [store, epicMiddleware];
};
</s,></a></a></s,></s,></s,></s,></s></s,>