import { ActionEdit } from '@robotsnacks/icons';
import { ToolbarButton } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';

type Props = { onDeleteClick?: () => void; onEditClick?: () => void } & Pick<
  BlockToolbarProps,
  'block' | 'parentItems'
>;

const TITLE = 'Code';

const CodeToolbar: SFC<props> = ({ onDeleteClick, onEditClick, ... nghỉ ngơi }) => (
  <blocktoolbar title="{TIÊU ĐỀ}" {...rest}="">
    <toolbarbutton onClick="{onEditClick}">
      <actionedit></actionedit>
    </toolbarbutton>
    <deleteblockflyout onDeleteClick="{onDeleteClick}" title="{TIÊU ĐỀ}"></deleteblockflyout>
    {/* <toolbarseparator></toolbarseparator>
    <toolbarbutton>
      <icon>Hành động:Cài đặt</icon>
    </toolbarbutton> */}
  </blocktoolbar>
);

xuất CodeToolbar mặc định;
</props>