import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';

import {
  GridItem,
  WithStyles,
  createStyles,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root' | 'child'>(() => ({
  root: {
    boxSizing: 'border-box',
    transition: 'box-shadow 300ms',
  },
  child: {
    flex: 1,
    display: 'flex',
    position: 'relative',
  },
}));

export type ReadOnlyGridItemBlockProps = BlockComponentProps;

type Props = WithStyles<readonlygriditemblockprops, typeof="" styles="">;

const defaultProps = Object.freeze({});

class ReadOnlyGridItemBlock extends Component<props> {
  static defaultProps = defaultProps;

  public render() {
    const { classes, block, renderChildren } = this.props;
    return (
      <griditem className="{classes.root}" id="{block.getKey()}" key="{block.getKey()}">
        <div className="{classes.child}">{renderChildren(khối)}</div>
      </griditem>
    );
  }
}

xuất mặc định vớiStyles(styles)(ReadOnlyGridItemBlock);
</props></readonlygriditemblockprops,>