import { List, Record } from 'immutable';
import { filter } from 'lodash';
import { APPEND_TO_ROOT_PATH } from './constants';
import genKey from './genKey';
import isDeleteOp from './isDeleteOp';
import isInsertOp from './isInsertOp';
import isReplaceOp from './isReplaceOp';
import { DeleteOp, InsertOp, Op, ReplaceOp } from './ops';
import toArrayPath from './toArrayPath';
import toStringPath from './toStringPath';

type DeltaNode = {
  children: DeltaNode[];
  attributes?: { [name: string]: any };
  key: string;
  type: string;
};

const DEFAULT_RECORD = {
  ops: List<op>(),
};

export interface DeltaJSON {
  ops: Op[];
}

/**
 * Throws an error indicating that a node with the specified key does not
 * exist.
 * @param key The key that doesn't exist.
 */
const throwDoesNotExist = (key: string): never => {
  throw new Error(`Node with key "${key}" does not exist.`);
};

/**
 * The Delta class represents a series of operations to perform on a
 * tree-structure.
 */
export default class Delta extends Record(DEFAULT_RECORD, 'Delta') {
  // static fromJSON<t extends="" Type="">(đầu vào: RawDelta): Delta<t> {
  //   return new Delta({
  //     ops: List(input.ops.map(op => fromRawOp(op))),
  //   });
  // }

  static insert(input: string | InsertOp): Delta {
    return new Delta().insert(input);
  }

  static replace(
    key: string,
    op?: Pick<replaceop, 'attributes'="" |="" 'type'="">,
  ): Delta {
    return new Delta().replace(key, op);
  }

  static duplicate(delta: DeltaJSON): DeltaJSON {
    if (delta && Array.isArray(delta.ops) && delta.ops[0]) {
      const ops = delta.ops.slice();
      const oldRootKey = (ops[0] as InsertOp).key;
      const newRootKey = genKey();
      ops[0] = {
        ...ops[0],
        key: newRootKey,
      };
      for (let i = 1; i < ops.length; i++) {
        const op = ops[i];
        const [key, n] = toArrayPath((op as InsertOp).insert);
        if (key === oldRootKey) {
          ops[i] = {
            ...op,
            insert: toStringPath(newRootKey, n),
          };
        }
      }
      return { ops };
    }
    return delta;
  }

  static del(key: string): Delta {
    return new Delta().del(key);
  }

  /**
   * Creates a new insert op and adds it to this delta.
   * @param input
   */
  insert(op: string | InsertOp): Delta {
    op =
      typeof op === 'string'
        ? {
            attributes: {},
            insert: APPEND_TO_ROOT_PATH,
            key: genKey(),
            type: op,
          }
        : op;
    return this.set(
      'ops',
      this.ops.push({
        attributes: {},
        ...op,
      }),
    );
  }

  /**
   * Creates a new delete op and adds it to this op.
   * @param key The key to delete.
   */
  del(key: string): Delta {
    return this.set('ops', this.ops.push({ delete: key }));
  }

  /**
   * Creates a new delete op and adds it to this op.
   * @param key The key to delete.
   */
  replace(key: string, op?: Pick<replaceop, 'attributes'="" |="" 'type'="">): Delta {
    return this.set(
      'ops',
      this.ops.push({
        attributes: {},
        replace: key,
        ...op,
      }),
    );
  }

  /**
   * Concatenates another delta to this delta.
   * @param deltas The delta to concatenate.
   */
  concat(deltas: Delta[]): this {
    return this.set(
      'ops',
      this.ops.concat(
        deltas.reduce(
          (acc, delta) => [...acc, ...delta.ops.toArray()],
          [] as Op[],
        ),
      ),
    );
  }

  /**
   * Compacts this delta to a minimal representation.
   */
  compact() {
    const tree: DeltaNode = { key: '', children: [], type: '' };
    this.ops.forEach(op => {
      if (isInsertOp(op)) {
        this._insertInto(tree, op);
      } else if (isDeleteOp(op)) {
        this._deleteFrom(tree, op);
      } else if (isReplaceOp(op)) {
        this._replaceIn(tree, op);
      } else {
        throw new Error('Invalid operation.');
      }
    });
    return this.set('ops', List(this._assembleOps(tree)));
  }

  reduce<p>(callback: (acc: P, op: Op) => P, accumulator: P): P {
    return this.ops.reduce(callback, accumulator);
  }

  filter(fn: (op: Op) => boolean): Delta {
    return this.set('ops', this.ops.filter(fn));
  }

  map</p><p>(callback: (op: Op) => P): Danh sách</p><p> {
    return this.ops.map(callback);
  }

  forEach(callback: (op: Op) => void): void {
    this.ops.forEach(callback);
  }

  compose(delta: Delta): Delta {
    return this.concat([delta]).compact();
  }

  /**
   * Assembles a new set of operations from a node tree.
   * @param node The root of the tree.
   */
  private _assembleOps(node: DeltaNode): InsertOp[] {
    let ops: InsertOp[] = [];
    for (let i = 0; i < node.children.length; i++) {
      const n = node.children[i];
      ops.push({
        attributes: n.attributes || {},
        insert: toStringPath([node.key, i.toString()]),
        key: n.key,
        type: n.type,
      });
    }
    for (let i = 0; i < node.children.length; i++) {
      ops = ops.concat(this._assembleOps(node.children[i]));
    }
    return ops;
  }

  /**
   * Executes an insert op on a node tree.
   * @param node The root node of the tree.
   * @param op The insert op to execute.
   */
  private _insertInto(node: DeltaNode, op: InsertOp) {
    const { insert: path, ...rest } = op;
    const [parentKey, index] = toArrayPath(path);
    const r = this._findNode(node, parentKey);
    if (!r) return throwDoesNotExist(parentKey);
    const { node: n } = r;
    const spliceIndex =
      index === '-0' ? n.children.length : parseInt(index, 10);

    n.children.splice(spliceIndex, 0, {
      children: [],
      ...rest,
    });
  }

  /**
   * Executes an insert op on a node tree.
   * @param node The root node of the tree.
   * @param op The insert op to execute.
   */
  private _replaceIn(node: DeltaNode, op: ReplaceOp) {
    const { replace: key, ...rest } = op;
    const r = this._findNode(node, key);
    if (!r) return throwDoesNotExist(key);
    const { node: n, parent, index } = r;
    if (!parent) throw new Error('Could not find node parent.');
    if (!index) throw new Error('Attempting to perform replaceIn on root.');
    parent.children.splice(index, 1, {
      ...n,
      ...rest,
    });
  }

  /**
   * Executes a delete op on the node tree.
   * @param node The root node of the tree.
   * @param op The delete op to execute.
   */
  private _deleteFrom(node: DeltaNode, op: DeleteOp) {
    const { delete: key } = op;
    const r = this._findNode(node, key);
    if (!r || !r.parent) return throwDoesNotExist(key);
    r.parent.children = filter(r.parent.children, n => n.key !== key);
  }

  /**
   * Finds a node by it's key within a tree by performing a depth-first search.
   * @param node The root of the tree or subtree to search.
   * @param key The key to search for.
   */
  private _findNode(
    node: DeltaNode,
    key: string,
  ):
    | { node: DeltaNode; parent: DeltaNode | null; index: number | null }
    | false {
    return this._dfs(node, (n, p, i) => {
      if (n.key === key) return { node: n, parent: p, index: i };
      return false;
    });
  }

  /**
   * Executes a depth-first search on the passed node.
   * @param node The node to search.
   * @param fn Callback function to execute.
   */
  private _dfs</p><p>(
    node: DeltaNode,
    fn: (
      node: DeltaNode,
      parent: DeltaNode | null,
      index: number | null,
    ) => P | false,
    root: boolean = true,
  ): P | false {
    if (root) {
      const r = fn(node, null, null);
      if (r !== false) return r;
    }
    for (let i = 0; i < node.children.length; i++) {
      const r = fn(node.children[i], node, i);
      if (r !== false) return r;
    }
    for (let i = 0; i < node.children.length; i++) {
      const r = this._dfs(node.children[i], fn, false);
      if (r !== false) return r;
    }
    return false;
  }
}
</p></replaceop,></replaceop,></t></t></op>