import { includes } from 'lodash';
import React, { CSSProperties, HTMLAttributes, ReactNode, SFC } from 'react';
import Arrow, { ArrowDirection } from '../Arrow';
import Paper, { PaperProps } from '../Paper';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    border: `1px solid ${theme.color('grey', '200')}`,
    borderRadius: 2,
    position: 'relative',
  },
}));

export type ArrowBoxProps = {
  arrowLeft?: number;
  arrowTop?: number;
  children?: ReactNode;
  color?: string;
  direction?: ArrowDirection;
  domRef?: (el: HTMLDivElement | null) => void;
  size?: number;
} & HTMLAttributes<htmldivelement> &
  Xỉa<paperprops, 'elevation'="">;

loại Props = WithStyles<arrowboxprops, typeof="" styles="">;

const calculateArrowStyle = (
  Đạo cụ: Chọn<props, 'arrowLeft'="" |="" 'arrowTop'="" 'direction'="">,
): CSSProperties => ({
  left:
    includes(['up', 'down'], props.direction) && props.arrowLeft
      ? `${props.arrowLeft}px`
      : undefined,
  top:
    includes(['left', 'right'], props.direction) && props.arrowTop
      ? `${props.arrowTop}px`
      : undefined,
});

const ArrowBox: SFC<props> = ({
  mũi tênTrái,
  mũi tênTrên cùng,
  trẻ em
  Lớp học
  className,
  màu
  hướng
  độ cao = 9,
  kích thước
  đề tài
  ... Props
}) => (
  <paper className="{cx(classes.root," className)}="" elevation="{elevation}" {...props}="">
    {trẻ em}
    <arrow color="{color}" size="{size}" direction="{direction}" style="{calculateArrowStyle({" arrowLeft,="" arrowTop,="" })}=""></arrow>
  </paper>
);

xuất mặc định vớiStyles (styles) (ArrowBox);
</props></props,></arrowboxprops,></paperprops,></htmldivelement>