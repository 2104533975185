import { ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';
import { SnipcartBlockAttributes } from './SnipcartBlockAttributes';
import SnipcartOptionsFlyout from './SnipcartOptionsFlyout';

type Props = {
  onDeleteClick?: () => void;
  attributes?: SnipcartBlockAttributes;
  onChange?: (attr: SnipcartBlockAttributes) => void;
} & Pick<blocktoolbarprops, 'block'="" |="" 'parentItems'="">;

const TITLE = 'Đoạn trích';

const SnipcartBlockThanh công cụ: SFC<props> = ({
  trênDeleteClick,
  trênThay đổi,
  Thuộc tính
  ... nghỉ ngơi
}) => (
  <blocktoolbar title="{TIÊU ĐỀ}" {...rest}="">
    <snipcartoptionsflyout onChange="{onChange}" attributes="{attributes}"></snipcartoptionsflyout>
    <toolbarseparator></toolbarseparator>
    <deleteblockflyout onDeleteClick="{onDeleteClick}" title="{TIÊU ĐỀ}"></deleteblockflyout>
  </blocktoolbar>
);

xuất SnipcartBlockToolbar mặc định;
</props></blocktoolbarprops,>