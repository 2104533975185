import { CodeBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    html: { type: 'identity' },
    source: { type: 'identity' },
  },
  icon: <codeblock></codeblock>,
  tên: 'MÃ',
  tiêu đề: 'Mã',
  v: 0,
};
