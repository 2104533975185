import React, { SFC } from 'react';
import getFormats from './getFormats';

import {
  RichTextEditor,
  RichTextEditorProps,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root'>(() => ({
  root: {
    flex: 1,
    padding: '0.5em',
    position: 'relative',
    '& *:first-child': {
      marginTop: 0,
    },
  },
}));

export type CardBlockRichTextEditorProps = RichTextEditorProps;
type Props = WithStyles<cardblockrichtexteditorprops, typeof="" styles="">;

const CardBlockRichTextTrình soạn thảo: SFC<props> = ({ lớp, ... nghỉ ngơi }) => (
  <richtexteditor className="{cx(classes.root)}" formats="{getFormats()}" {...rest}=""></richtexteditor>
);

xuất mặc định vớiStyles (styles) (CardBlockRichTextEditor);
</props></cardblockrichtexteditorprops,>