import { TextBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    html: { type: 'identity' },
    ops: { type: 'identity' },
  },
  icon: <textblock></textblock>,
  tên: 'RICHTEXT',
  tiêu đề: 'Văn bản đa dạng thức',
  v: 0,
};
