import { WithStyles, createStyles, withStyles } from '@robotsnacks/ui';
import React, { Component, HTMLAttributes } from 'react';

const styles = createStyles<'root'>(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    width: '100%',
  },
}));

export interface CardBlockWrapperProps extends HTMLAttributes<htmldivelement> {}

loại Props = WithStyles<cardblockwrapperprops, typeof="" styles="">;

class CardBlockWrapper extends Component<props> {
  render() {
    const { children, classes, id, theme, className, ...rest } = this.props;
    const clsName = [classes.root, this.props.className || ''].join(' ').trim();
    return (
      <div className="{clsName}" {...rest}="">
        {trẻ em}
      </div>
    );
  }
}

xuất mặc định vớiStyles (styles) (CardBlockWrapper);
</props></cardblockwrapperprops,></htmldivelement>