import { Typography, WithStyles, withStyles } from '@robotsnacks/ui';
import { DateTime, Duration } from 'luxon';
import React, { Component, ReactNode } from 'react';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import ToolbarHover from '../ToolbarHover';
import ToolbarWrapper from '../ToolbarWrapper';
import { EventBlockAttributes } from './EventBlockAttributes';
import EventBlockPlaceholder from './EventBlockPlaceholder';
import EventBlockToolbar from './EventBlockToolbar';
import EventBlockWrapper from './EventBlockWrapper';
import style from './style';

export interface EventBlockProps
  extends BlockComponentProps<eventblockattributes> {
  onDelete?: (block: Block<eventblockattributes>) => void;
  eventList?: Array<{
    description?: string;
    id: string;
    imageId?: string;
    title: string;
    start: DateTime;
    duration: Duration;
  }>;
  parentToolbar?: ReactNode;
  parentToolbarItems?: ReactNode;
}

type Props = WithStyles<eventblockprops, typeof="" style="">;

class EventBlock mở rộng Component<props, {}=""> {
  public render() {
    const { block, parentToolbar, parentToolbarItems } = this.props;
    const title = block.getAttribute('title');

    return (
      <toolbarhover block="{block}">
        <eventblockwrapper id="{block.getKey()}">
          <toolbarwrapper>
            {parentToolbar}
            <eventblocktoolbar block="{block}" onDeleteClick="{this._handleDeleteClick}" parentItems="{parentToolbarItems}"></eventblocktoolbar>
          </toolbarwrapper>
          {!title &&this.renderPlaceholder()}
          {title &&&this.renderEvent()}
        </eventblockwrapper>
      </toolbarhover>
    );
  }

  private renderPlaceholder() {
    return (
      <eventblockplaceholder onSelectEvent="{event" ==""> {
          const { block, onChange, getValue } = this.props;
          const updatedBlock = block
            .setAttribute('eventId', event.id)
            .setAttribute('title', event.title)
            .setAttribute('start', event.start.toISO())
            .setAttribute('duration', event.duration.toISO());
          onChange(getValue().replace(updatedBlock));
        }}
        eventList={this.props.eventList}
        onCreateEvent={event => {
          const { block, onChange, getValue } = this.props;
          const start = DateTime.fromJSDate(
            new Date(`${event.date} ${event.startTime}`),
          ).toUTC();
          const end = DateTime.fromJSDate(
            new Date(`${event.date} ${event.endTime}`),
          ).toUTC();
          const updatedBlock = block
            .setAttribute('title', event.title)
            .setAttribute('start', start.toISO())
            .setAttribute('duration', end.diff(start).toISO());
          onChange(getValue().replace(updatedBlock));
        }}
      />
    );
  }

  private renderEvent() {
    const { block, classes } = this.props;
    const d = block.getAttribute('duration');
    const title = block.getAttribute('title');
    const start = DateTime.fromISO(block.getAttribute('start'));
    const duration = d ? Duration.fromISO(d) : null;
    return (
      <typography className="{classes.root}">
        <div className="{classes.date}">
          <span className="{classes.month}">{start.monthShort}</span>
          <span className="{classes.day}">{start.day}</span>
          <span className="{classes.year}">{start.year}</span>
        </div>
        <div className="{classes.text}">
          <h2 className="{classes.title}">{tiêu đề}</h2>
          <p className="{classes.fullDate}">{start.toFormat('DDDD')}</p>
          <p className="{classes.time}">
            {start.toLocaleString(DateTime.TIME_SIMPLE)}
            {duration && (
              <>
                —{' '}
                {start.plus(duration).toLocaleString(DateTime.TIME_SIMPLE)}
                <span className="{classes.timezone}">
                  {start.toFormat('ZZZZ')}
                </span>
              
            )}
          </p>
        </div>
      </typography>
    );
  }

  private _handleDeleteClick = () => {
    const { block, getValue, onChange, onDelete } = this.props;
    onChange(getValue().del(block));
    if (onDelete) onDelete(block);
  };
}

export default withStyles(style)(EventBlock);
</eventblockplaceholder></props,></eventblockprops,></eventblockattributes></eventblockattributes>