import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';
import { BlockPickerProps } from '../BlockPicker';

export interface ReadOnlyStackBlockProps
  extends BlockComponentProps,
    Pick<blockpickerprops, 'blocks'=""> {}

type Props = ReadOnlyStackBlockProps;

type State = {
  clientY?: number;
  activeLineIndex?: number;
};

const initialState: State = Object.freeze({});

export default class ReadOnlyStack extends Component<props, State=""> {
  state = initialState;

  public render() {
    const { block, renderChildren } = this.props;
    return <div>{renderChildren(khối)}</div>;
  }
}
</props,></blockpickerprops,>