import { cx } from '@robotsnacks/ui';
import React, { Component } from 'react';
import BaseGridBlock, { BaseGridBlockProps } from '../BaseGridBlock';

export type GridBlockProps = BaseGridBlockProps;

export default class GridBlock extends Component<gridblockprops> {
  render() {
    trở về (
      <basegridblock title="Lưới" {...this.props}="" className="{cx('cs-grid')}"></basegridblock>
    );
  }
}
</gridblockprops>