import { DateTime, Duration } from 'luxon';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';
import CardOptionsFlyout from './CardOptionsFlyout';
import EventListFlyout from './EventListFlyout';

interface Props extends Pick<blocktoolbarprops, 'block'="" |="" 'parentItems'=""> {
  onDeleteClick?: () => void;
  eventList?: any[];
  onEventSelect?: (event: any) => void;
  onShowSponsorChange?: (showSponsor: boolean) => void;
  showSponsor?: boolean;
}

const TITLE = 'Event Card';

const EventCardBlockToolbar: SFC<props> = ({
  trênDeleteClick,
  eventDanh sách,
  trênEventSelect,
  showNhà tài trợ,
  onShowSponsorChange,
  ... nghỉ ngơi
}) => (
  <blocktoolbar title="{TIÊU ĐỀ}" {...rest}="">
    <eventlistflyout href="{(rest" as="" any).block.getAttribute('href')}="" onSelect="{onEventSelect}"></eventlistflyout>
    <cardoptionsflyout showSponsor="{showSponsor}" onShowSponsorChange="{onShowSponsorChange}"></cardoptionsflyout>
    <deleteblockflyout onDeleteClick="{onDeleteClick}" title="{TIÊU ĐỀ}"></deleteblockflyout>
  </blocktoolbar>
);

xuất mặc định EventCardBlockToolbar;
</props></blocktoolbarprops,>