import { QueryHookOptions, useQuery } from 'react-apollo';
import { GET_PAGE_QUERY } from './get-page-query';
import { GetPageData } from './get-page-data';
import { GetPageVariables } from './get-page-variables';

export const useGetPageQuery = (
  variables: GetPageVariables,
  options?: Omit<
    QueryHookOptions<getpagedata, GetPageVariables="">,
    'biến' | 'truy vấn'
  >,
) =>
  useQuery<getpagedata, GetPageVariables="">(GET_PAGE_QUERY, {
    ...options,
    variables,
  });
</getpagedata,></getpagedata,>