import { DeckBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: { breakpoints: { type: 'identity' } },
  icon: <deckblock></deckblock>,
  tên: 'DECK',
  tiêu đề: 'Deck',
  v: 0,
};
