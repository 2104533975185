import { RichTextEditorProps } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import CardBlockRichTextEditor from './CardBlockRichTextEditor';

export interface CardBlockBodyProps
  extends Pick<richtexteditorprops, 'value'=""> {}

type Props = CardBlockBodyProps;

const ReadOnlyCardBlockBody: SFC<props> = ({ giá trị }) => (
  <cardblockrichtexteditor value="{value}" readOnly=""></cardblockrichtexteditor>
);

xuất mặc định ReadOnlyCardBlockBody;
</props></richtexteditorprops,>