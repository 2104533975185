import { GridEngine, GridEngineItem, GridView } from '@robotsnacks/ui';
import { floor, partial, pick, reduce, sum, values } from 'lodash';
import React, { Component } from 'react';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import { BlockPickerProps } from '../BlockPicker';
import { GridItemDragEvent, GridItemResizeEvent } from '../GridItemBlock';
import Placeholder from '../Placeholder';
import ToolbarHover from '../ToolbarHover';
import ToolbarWrapper from '../ToolbarWrapper';
import { gridItem } from '../blocks';
import Delta, { genKey, toStringPath } from '../delta';
import { invariant, shouldBlockComponentUpdate } from '../utils';
import BaseGrid from './BaseGrid';
import BaseGridBlockToolbar from './BaseGridBlockToolbar';
import getInsertData from './getInsertData';

import {
  BaseGridBlockAttributes,
  BaseGridBlockBreakpointAttribute,
  BaseGridBlockBreakpointAttributeMap,
  BaseGridBlockDefaultBreakpointAttribute,
} from './BaseGridBlockAttributes';

type ViewMap = { [breakpointName: string]: GridView };

export interface BaseGridBlockProps
  extends BlockComponentProps<basegridblockattributes>,
    Xỉa<blockpickerprops, 'blocks'=""> {
  defaults: { [name: string]: BaseGridBlockDefaultBreakpointAttribute };
  onDelete?: (block: Block<basegridblockattributes>) => void;
  onHidePopup?: () => void;
  onShowPopup?: () => void;
  publicClassName?: string;
  className?: string;
  title?: string;
}

type Props = BaseGridBlockProps;

type State = {
  dragging?: boolean;
  resizing?: boolean;
  views?: ViewMap;
};

const defaultProps = Object.freeze({
  publicClassName: 'composer-blk-grid',
});

const initialState = Object.freeze({
  dragging: false,
  resizing: false,
  views: {} as ViewMap,
});

const itemInvariant = (item: GridEngineItem | null) =>
  invariant(item, `Grid engine item not found`);

export default class BaseGridBlock extends Component<props, State=""> {
  static defaultProps = defaultProps;
  state = initialState;

  private _gridRef: any;
  private _placeholderId: string = genKey();

  /**
   * When the grid mounts, place the saved items into the grid.
   */
  componentWillMount() {
    this._createGridViews();
  }

  shouldComponentUpdate(props: Props, state: State) {
    return (
      state.views !== this.state.views ||
      (shouldBlockComponentUpdate(props, this.props) &&
        !this.state.dragging &&
        !this.state.resizing)
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.block !== this.props.block) {
      this._createGridViews();
    }
  }

  render() {
    const { block, className } = this.props;
    return (
      <toolbarhover block="{block}">
        <basegrid className="{className}" gridRef="{this._setGridRef}" id="{block.getKey()}" measure="" views="{values(this.state.views)}">
          {!this._hasUpperLeftItem() && (
            <toolbarwrapper>{this._renderToolbar()}</toolbarwrapper>
          )}
          {this._renderGridChildren()}
        </basegrid>
      </toolbarhover>
    );
  }

  /* ---------------------------------------------------------------------------
   * Child Rendering Methods
   * ------------------------------------------------------------------------ */

  private _renderGridChildren() {
    if (this.props.block.getChildren().size > 0) {
      return this._renderChildBlocks();
    } else {
      return this._renderPlaceholder();
    }
  }

  private _renderPlaceholder() {
    return (
      <placeholder id="{this._placeholderId}" onSelect="{this._handlePlaceholderSelect}" blocks="{this.props.blocks}"></placeholder>
    );
  }

  private _renderChildBlocks() {
    const view = this._getActiveView();
    const rows = view.engine.rows;
    return this.props.block.getChildren().map((block, i) => {
      const item = view.engine.getItem(block.getKey());
      if (!item) {
        throw new Error(
          `Grid item for block ${block.getKey()} not in grid view.`,
        );
      }
      return this.props.renderBlock(block, {
        blocks: this.props.blocks,
        column: item.column,
        firstColumn: item.column === 0,
        firstRow: item.row === 0,
        height: item.height,
        lastColumn: item.column + item.width === view.columns,
        lastRow: item.row + item.height === rows,
        onDelete: this._handleChildDelete,
        onDrag: this._handleDrag,
        onDragEnd: this._handleDragOrResizeEnd,
        onDragStart: this._handleDragStart,
        onResize: this._handleResize,
        onResizeEnd: this._handleDragOrResizeEnd,
        onResizeStart: this._handleResizeStart,
        onSelect: partial(this._handleSelect, i),
        parentToolbar:
          item.column === 0 && item.row === 0
            ? this._renderToolbar()
            : undefined,
        row: item.row,
        width: item.width,
      });
    });
  }

  private _renderToolbar() {
    return (
      <basegridblocktoolbar block="{this.props.block}" onDeleteClick="{this._handleDeleteClick}" title="{this.props.title}"></basegridblocktoolbar>
    );
  }

  /* ---------------------------------------------------------------------------
   * Resize/Drag Handlers
   * ------------------------------------------------------------------------ */

  private _handleDragStart = () => {
    this.setState({ dragging: true });
  };

  private _handleDrag = (e: GridItemDragEvent) => {
    const current = this._gridRef;
    if (!current) return;
    const { columns, rows } = current.getMeasurements();
    const activeView = this._getActiveView();
    const gridRect = current.getDomElement()!.getBoundingClientRect();
    const item = activeView.engine.getItem(e.key) as GridEngineItem;

    itemInvariant(item);

    const top = e.top - gridRect.top;
    const left = e.left - gridRect.left;

    let row = 0;
    let column = 0;

    for (let i = 0; i < columns.length; i++) {
      if (sum(columns.slice(0, i)) < left) {
        column++;
      }
    }

    for (let i = 0; i < rows.length; i++) {
      if (sum(rows.slice(0, i)) < top) {
        row++;
      }
    }

    if (item.column !== column || item.row !== row) {
      this.setState({
        views: this._modifyActiveView(view => {
          view.engine = view.engine
            .removeItem(item.id)
            .insertItem(item.set('row', row).set('column', column))
            .compact()
            .trim();
          return view;
        }),
      });
    }
  };

  private _handleResizeStart = () => {
    this.setState({ resizing: true });
  };

  /**
   * Handles an item resize event.
   */
  private _handleResize = (e: GridItemResizeEvent) => {
    const { key } = e;
    const activeView = this._getActiveView();
    const item = activeView.engine.getItem(key) as GridEngineItem;
    itemInvariant(item);

    const { height, width } = this._calculateGridSize(
      item.column,
      floor(e.height),
      floor(e.width),
    );

    if (height !== item.height || width !== item.width) {
      // Insert the item into the grid at it's new height and width.
      this.setState({
        views: this._modifyActiveView(view => {
          view.engine = view.engine
            .removeItem(item.id)
            .insertItem(item.set('height', height).set('width', width))
            .compact()
            .trim();
          return view;
        }),
      });
    }
  };

  /**
   * Handles an item resize end event.
   */
  private _handleDragOrResizeEnd = () => {
    this.setState({ dragging: false, resizing: false }, () => {
      const { block, getValue, onChange } = this.props;
      const value = getValue().apply(
        Delta.replace(block.getKey(), {
          attributes: {
            breakpoints: this._calcBlockData(this.state.views as any),
          },
        }),
      );
      onChange(value);
    });
  };

  /* ---------------------------------------------------------------------------
   * View Modifiers
   * ------------------------------------------------------------------------ */

  private _modifyActiveView(fn: (view: GridView) => GridView): ViewMap {
    const { currentBreakpoint } = this.props;
    if (!currentBreakpoint) throw new Error('No current breakpoint.');
    const { views } = this.state;
    const updatedView = fn(this._getActiveView());
    return {
      ...views,
      [currentBreakpoint]: updatedView,
    };
  }

  /* ---------------------------------------------------------------------------
   * View Getters
   * ------------------------------------------------------------------------ */

  private _getActiveView(): GridView {
    const { currentBreakpoint } = this.props;
    const { views } = this.state;
    if (!views) throw new Error('No views defined in state.');
    if (!currentBreakpoint) throw new Error('No current breakpoint.');
    return views[currentBreakpoint];
  }

  /* ---------------------------------------------------------------------------
   * Value Modifiers
   * ------------------------------------------------------------------------ */

  private _handleDeleteClick = () => {
    const { block, getValue, onChange, onDelete } = this.props;
    onChange(getValue().del(block));
    if (onDelete) onDelete(block);
  };

  private _handleChildDelete = (child: Block) => {
    const { block, getValue, onChange } = this.props;

    const views = reduce(
      this.state.views,
      (acc, view, breakpointName) => {
        const updatedEngine = view.engine
          .removeItem(child.getKey())
          .compact()
          .trim();
        return {
          ...acc,
          [breakpointName]: {
            ...view,
            engine: updatedEngine,
          },
        };
      },
      {} as ViewMap,
    );

    onChange(
      getValue()
        .replace(block.setAttribute('breakpoints', this._calcBlockData(views)))
        .del(child),
    );

    this.setState({ views });
  };

  private _handleSelect = (
    childIndex: number,
    side: 'north' | 'south' | 'east' | 'west',
    type: string,
  ) => {
    const { block, getValue, onChange } = this.props;
    const gridItemKey = genKey();
    const childGridItemBlock: any = block.getChildren().get(childIndex);

    const views = reduce(
      this.state.views,
      (acc, view, breakpointName) => {
        const data: any = getInsertData(
          view,
          side,
          childGridItemBlock.getKey(),
        );
        const updatedEngine = view.engine
          .insertItem({
            ...data,
            id: gridItemKey,
          })
          .compact()
          .trim();
        return {
          ...acc,
          [breakpointName]: {
            ...view,
            engine: updatedEngine,
          },
        };
      },
      {} as ViewMap,
    );

    // const sibling = block.getChildren().get(childIndex) as Block;
    // console.log(sibling.getAttributes());

    // TODO: Copy attributes from sibling if types match.

    this.setState({ views }, () => {
      const updatedValue = getValue().apply(
        Delta.replace(block.getKey(), {
          attributes: {
            breakpoints: this._calcBlockData(views),
          },
        })
          .insert({
            attributes: {},
            insert: toStringPath([block.getKey(), '-0']),
            key: gridItemKey,
            type: gridItem.name,
          })
          .insert({
            type,
            attributes: {},
            insert: toStringPath([gridItemKey, 0]),
            key: genKey(),
          }),
      );

      onChange(updatedValue);
    });
  };

  private _handlePlaceholderSelect = (type: string) => {
    const { defaults, block, getValue, onChange } = this.props;
    const gridItemKey = genKey();

    const views = reduce(
      this.state.views,
      (acc, view, breakpointName) => {
        const { size } = defaults[breakpointName];

        // Insert the item into the grid engine to get it's actual position.
        const updatedEngine = view.engine
          .removeItem(this._placeholderId)
          .insertItem({
            ...size,
            column: 0,
            id: gridItemKey,
            row: 0,
          })
          .compact()
          .trim();

        return {
          ...acc,
          [breakpointName]: {
            ...view,
            engine: updatedEngine,
          },
        };
      },
      {} as ViewMap,
    );

    this.setState({ views }, () => {
      const updatedValue = getValue().apply(
        Delta.replace(block.getKey(), {
          attributes: {
            breakpoints: this._calcBlockData(views),
          },
        })
          .insert({
            attributes: {},
            insert: toStringPath([block.getKey(), 0]),
            key: gridItemKey,
            type: gridItem.name,
          })
          .insert({
            type,
            attributes: {},
            insert: toStringPath([gridItemKey, 0]),
            key: genKey(),
          }),
      );

      onChange(updatedValue);
    });
  };

  /* ---------------------------------------------------------------------------
   * Attribute Calculators
   * ------------------------------------------------------------------------ */

  private _calculateGridSize(
    column: number,
    heightPx: number,
    widthPx: number,
  ) {
    const current = this._gridRef;
    if (!current) throw new Error('no grid');
    const { columns, rows } = current.getMeasurements();
    const view = this._getActiveView();

    let width = 1;
    let height = 0;
    let colCounter = 0;

    const heightOfGridInPixels = sum(rows);
    for (let i = column; i < columns.length; i++) {
      colCounter += columns[i];
      if (colCounter < widthPx) width++;
    }

    if (heightPx > heightOfGridInPixels) {
      height = rows.length + 1;
    } else {
      for (let i = 0; i < rows.length; i++) {
        if (sum(rows.slice(0, i)) < heightPx) {
          height++;
        }
      }
    }

    const overflows =
      column + width > view.columns! && view.engine.flow === 'down';

    return {
      height,
      width: overflows ? view.columns! - column : width,
    };
  }

  private _calcBlockData(views: ViewMap): BaseGridBlockBreakpointAttributeMap {
    return reduce(
      views,
      (acc, view, name) => ({
        ...acc,
        [name]: {
          ...pick(view, 'columns', 'columnWidth', 'rows', 'rowHeight'),
          flow: view.engine.flow,
          items: view.engine.sortedItems().toArray(),
        },
      }),
      {} as { [name: string]: BaseGridBlockBreakpointAttribute },
    );
  }

  private _createGridViews() {
    const { block, breakpoints, defaults, getBreakpointMedia } = this.props;
    const breakpointsAttr = block.getAttribute('breakpoints') || {};
    const views = reduce(
      breakpoints,
      (acc, _, breakpointName) => {
        const curr = breakpointsAttr[breakpointName];
        const { items = [], ...rest } =
          curr || (defaults[breakpointName] as any);
        const media = getBreakpointMedia(breakpointName) as string;
        const initialItems =
          !items || items.length === 0
            ? this._createDefaultItems(breakpointName)
            : items;
        const engine = GridEngine.fromItems(initialItems, rest.flow);
        return {
          ...acc,
          [breakpointName]: {
            ...rest,
            engine,
            media,
          },
        };
      },
      {} as ViewMap,
    );
    this.setState({ views });
  }

  private _createDefaultItems(breakpointName: string) {
    const { size } = this.props.defaults[breakpointName];
    return [
      {
        ...size,
        column: 0,
        id: this._placeholderId,
        row: 0,
      },
    ];
  }

  /* ---------------------------------------------------------------------------
   * Miscellaneous Helpers
   * ------------------------------------------------------------------------ */

  private _setGridRef = (ref: any) => {
    this._gridRef = ref;
  };

  /**
   * Returns `true` or `false` indicating if the grid has an item in the upper-
   * left corner.
   */
  private _hasUpperLeftItem = () => {
    try {
      const view = this._getActiveView();
      return this.props.block.getChildren().some(block => {
        const item = view.engine.getItem(block.getKey());
        if (!item) return false;
        return item.column === 0 && item.row === 0;
      });
    } catch (e) {
      return false;
    }
  };
}
</props,></basegridblockattributes></blockpickerprops,></basegridblockattributes>