import Block from '../Block';
import ToolbarContext, { ToolbarContextType } from '../ToolbarContext';

import React, {
  Component,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  cloneElement,
  isValidElement,
} from 'react';

type ChildProps = {
  onMouseMoveCapture: MouseEventHandler;
  onMouseOutCapture: MouseEventHandler;
};

export interface ToolbarHoverProps<t extends="" ChildProps=""> {
  block: Block<any>;
  trẻ em: ((props: ChildProps) => ReactNode) | ReactElement<t>;
  độ trễ?: số;
}

loại Đạo cụ<t extends="" ChildProps=""> = Thanh công cụHoverProps<t> & typeof defaultProps;

const defaultProps = Object.freeze({
  delay: 2500,
});

export default class ToolbarHover<t extends="" ChildProps=""> mở rộng Thành phần<
  Props<t>
> {
  static defaultProps = defaultProps;

  private _timer: any = null;
  private _ctx: ToolbarContextType | null = null;

  componentWillUnmount() {
    this._clearTimer();
    if (this._ctx) {
      this._ctx.mouseOut(this.props.block);
      this._ctx.blur(this.props.block);
    }
  }

  render() {
    const { children } = this.props;
    const props = {
      onMouseMoveCapture: this._handleMouseMove,
      onMouseOutCapture: this._handleMouseOut,
    };
    return (
      <toolbarcontext.consumer>
        {context => {
          this._ctx = context;
          if (typeof children === 'function') {
            return children(props);
          }
          if (isValidElement(children)) {
            return cloneElement(children as any, props);
          }
        }}
      </toolbarcontext.consumer>
    );
  }

  private _clearTimer() {
    if (this._timer) clearTimeout(this._timer);
  }

  private _handleMouseMove = () => {
    this._clearTimer();
    this._timer = setTimeout(this._handleMouseOut, this.props.delay);
    if (this._ctx) this._ctx.mouseOver(this.props.block);
  };

  private _handleMouseOut = () => {
    if (this._ctx) this._ctx.mouseOut(this.props.block);
  };
}
</t></t></t></t></t></any></t>