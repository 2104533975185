// import { ToolbarButton, ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';

type Props = { onDeleteClick?: () => void } & Pick<
  BlockToolbarProps,
  'block' | 'parentItems'
>;

const TITLE = 'Rich Text';

const TitleBlockToolbar: SFC<props> = ({ onDeleteClick, ... nghỉ ngơi }) => (
  <blocktoolbar title="{TIÊU ĐỀ}" {...rest}="">
    <deleteblockflyout onDeleteClick="{onDeleteClick}" title="{TIÊU ĐỀ}"></deleteblockflyout>
    {/* <toolbarseparator></toolbarseparator> */}
    {/* <toolbarbutton>
      <icon>Hành động:Cài đặt</icon>
    </toolbarbutton> */}
  </blocktoolbar>
);

TitleBlockToolbar.displayName = 'RichTextBlockToolbar';

xuất mặc định TitleBlockToolbar;
</props>