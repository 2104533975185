import { Typography } from '@robotsnacks/ui';
import React, { Component } from 'react';
import Block from '../Block';
import { CodeBlockAttributes } from './CodeBlockAttributes';

type Props = {
  block: Block<codeblockattributes>;
  xem trước?: chuỗi;
  chỉ đọc?: boolean;
};

export default class Code extends Component<props> {
  private el: HTMLElement = null;
  private scripts: HTMLScriptElement[] = [];

  public shouldComponentUpdate(prevProps: Props) {
    const currentHtml =
      this.props.preview || this.props.block.getAttribute('html');
    const prevHtml = prevProps.preview || prevProps.block.getAttribute('html');
    return currentHtml !== prevHtml;
  }

  public componentDidUpdate() {
    this.unmountAllScripts();
    if (!this.props.readonly) {
      this.updateScripts();
    }
  }

  public componentWillUnmount() {
    this.unmountAllScripts();
  }

  public render() {
    const { block, preview } = this.props;
    return (
      <typography domRef="{el" ==""> (this.el = el)}
        style={{ flex: 1, minHeight: '10px' }}
        dangerouslySetInnerHTML={{
          __html: preview || block.getAttribute('html') || '',
        }}
      />
    );
  }

  private unmountAllScripts() {
    this.scripts.forEach(script => {
      try {
        script.parentNode.removeChild(script);
      } catch (e) {
        console.error('error removing script', e);
      }
    });
    this.scripts = [];
  }

  private updateScripts() {
    Array.from(this.el.querySelectorAll('script')).forEach(
      (s: HTMLScriptElement) => {
        try {
          // const script: HTMLScriptElement = s.cloneNode() as any;
          const script = document.createElement('script');
          // tslint:disable-next-line
          for (let i = 0; i < s.attributes.length; i++) {
            const attr = s.attributes[i];
            script.setAttribute(attr.name, attr.value);
          }
          script.innerHTML = s.innerHTML;
          document.head.appendChild(script);
          this.scripts.push(script);
          s.parentNode.removeChild(s);
        } catch (e) {
          // tslint:disable-next-line
          console.log('Error in script.', e);
        }
      },
    );
  }
}
</typography></props></codeblockattributes>