import { TextBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    title: { type: 'identity' },
    timestamp: { type: 'identity' },
  },
  icon: <textblock></textblock>,
  tên: 'TITLE',
  tiêu đề: 'Tiêu đề',
  v: 0,
};
