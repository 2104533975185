import { ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';
import CardOptionsFlyout from './CardOptionsFlyout';
import PageListFlyout from './PageListFlyout';

type TmpPage = {
  description?: string;
  id: string;
  imageId?: string;
  title: string;
  to: string;
};

interface Props extends Pick<blocktoolbarprops, 'block'="" |="" 'parentItems'=""> {
  onDeleteClick?: () => void;
  onLinkSelect?: (page: TmpPage | string) => void;
  onShowSponsorChange?: (showSponsor: boolean) => void;
  showSponsor?: boolean;
}

const TITLE = 'Card';

const CardToolbar: SFC<props> = ({
  trênDeleteClick,
  trênLinkSelect,
  showNhà tài trợ,
  onShowSponsorChange,
  ... nghỉ ngơi
}) => (
  <blocktoolbar title="{TIÊU ĐỀ}" {...rest}="">
    <pagelistflyout href="{(rest" as="" any).block.getAttribute('href')}="" onSelect="{onLinkSelect}" onSaveClick="{(h:" string)=""> trênLinkSelect! (h)}
    />
    <cardoptionsflyout showSponsor="{showSponsor}" onShowSponsorChange="{onShowSponsorChange}"></cardoptionsflyout>
    <toolbarseparator></toolbarseparator>
    <deleteblockflyout onDeleteClick="{onDeleteClick}" title="{TIÊU ĐỀ}"></deleteblockflyout>
    {/* <toolbarseparator></toolbarseparator>
    <toolbarbutton>
      <icon>Hành động:Cài đặt</icon>
    </toolbarbutton> */}
  </pagelistflyout></blocktoolbar>
);

xuất CardToolbar mặc định;
</props></blocktoolbarprops,>