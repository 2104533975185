import ImageClient from '@robotsnacks/image-client';
import React, { Component } from 'react';
import CardBlockImage from './CardBlockImage';

import {
  CardBlockBreakpointMediaAttributes,
  CardBlockMediaOrientation,
  CardBlockMediaType,
} from './CardBlockAttributes';

import {
  CardMedia,
  ImageUploaderProps,
  ResizeController,
  ResizeControllerProps,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root' | 'resizeHandle' | 'horizontal'>(theme => ({
  root: {
    padding: '0.5em',
    position: 'relative',
  },
  resizeHandle: {
    bottom: '-3px',
    cursor: 'row-resize',
    height: '6px',
    left: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 20,
    '&$horizontal': {
      bottom: 'auto',
      cursor: 'col-resize',
      height: '100%',
      left: 'auto',
      right: 0,
      top: 0,
      width: 6,
    },
  },
  horizontal: {},
}));

export interface CardBlockMediaProps
  extends Pick<resizecontrollerprops, 'onResize'="" |="" 'onResizeEnd'="">,
    Xỉa<imageuploaderprops, 'onUploadComplete'=""> {
  imageClient?: ImageClient;
  media: CardBlockBreakpointMediaAttributes;
  uploadUri?: string;
  uploadOptions?: any;
  resizing?: boolean;
}

type Props = WithStyles<cardblockmediaprops, typeof="" styles="">;

type State = {};

class CardBlockMedia extends Component<props, State=""> {
  private _elRef: HTMLElement | null = null;

  render() {
    const { media, classes, onResize, onResizeEnd } = this.props;
    return (
      <resizecontroller target="{this._getTarget}" onResize="{onResize}" onResizeEnd="{onResizeEnd}">
        {({ startResize, thay đổi kích thước }) => (
          <cardmedia className="{cx()}" domRef="{this._setDOMRef}" style="{this._getStyle()}">
            {this._renderMedia(thay đổi kích thước)}
            <div className="{cx(" classes.resizeHandle,="" media.orientation="==" CardBlockMediaOrientation.Horizontal="" &&="" classes.horizontal,="" )}="" onMouseDown="{e" ==""> startResize(e, { anchor: 'south' })}
            />
          </div></cardmedia>
        )}
      </resizecontroller>
    );
  }

  private _getStyle() {
    const { height, width, orientation } = this.props.media;
    if (orientation === CardBlockMediaOrientation.Vertical && height && width) {
      return {
        height: `0`,
        width: '100%',
        paddingBottom: `${Math.floor((height / width) * 100)}%`,
      };
    } else {
      return {
        height: `100%`,
        width: `${width}px`,
      };
    }
  }

  private _renderMedia(mediaResizing: boolean) {
    const {
      media,
      onUploadComplete,
      imageClient,
      uploadOptions,
      uploadUri,
      resizing,
    } = this.props;

    const { type } = media;

    if (type === CardBlockMediaType.Image) {
      return (
        <cardblockimage client="{imageClient}" media="{media}" onUploadComplete="{onUploadComplete}" uploadOptions="{uploadOptions}" uploadUri="{uploadUri}" resizing="{resizing" ||="" mediaResizing}=""></cardblockimage>
      );
    }
  }

  private _setDOMRef = (el: HTMLElement | null) => {
    this._elRef = el;
  };

  private _getTarget = () => {
    return this._elRef;
  };
}

export default withStyles(styles)(CardBlockMedia);
</props,></cardblockmediaprops,></imageuploaderprops,></resizecontrollerprops,>