import { BodyText, Button, TextInput } from '@mint/ui';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardsetterMark } from '@cardsetter/brand';
import { Link } from 'react-router-dom';
import { RedirectWithStatus } from '../redirect-with-status';
import { isLoggedIn } from '../../../selectors';
import { loginRequest } from '../../../actions';
import styled from 'styled-components';

const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  h1 {
    margin-bottom: 2rem;
  }

  a {
    display: block;
    text-align: center;
    font-size: 12px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
  }
`;

const Form = styled.div`
  flex: 1;
  padding: 1rem 1rem 4rem;
  max-width: 400px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

const MarkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;

  svg {
    font-size: 4rem;
    flex: 1;
  }
`;

export const AdminLoginPage: React.FunctionComponent = (): React.ReactElement => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const loggedIn = useSelector(isLoggedIn);

  if (loggedIn && typeof window !== 'undefined') {
    // Redirect the user to the homepage and break the cache with the `n`
    // query parameter.
    window.location.href = `/?n=${Date.now()}`;
  } else if (loggedIn) {
    return <redirectwithstatus status="{302}" to="/"></redirectwithstatus>;
  }

trở về (
    <main>
      <form>
        <markwrapper>
          <cardsettermark></cardsettermark>
        </markwrapper>
        <textinput label="Email" value="{email}" onChange="{(v):" void=""> setEmail(v.target.value)}
        />
        <textinput label="Password" value="{password}" type="password" onChange="{(v):" void=""> setMật khẩu(v.target.value)}
        />
        <buttonwrapper>
          <button onClick="{():" void=""> {
              dispatch(loginRequest({ email, password }));
            }}
          >
            Log In
          </button>
        </buttonwrapper>
        <bodytext>
          <link to="/admin/reset">Đặt lại mật khẩu
        </bodytext>
      </textinput></textinput></form>
    </main>
  );
};
